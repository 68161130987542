<template>
  <component
    :is="!currentStep ? 'LinkExpired' : (`Step${currentStep}`)"
    @submit="onNext"
    @error="onError"
    @complete="onComplete"
  />
</template>

<script>
import { computed, defineAsyncComponent, onBeforeUnmount } from 'vue';

import { router } from '@/router';
import { useTemporaryStore } from '@/store';

export default {
  name: 'AdminRegistration',
  components: {
    Step1: defineAsyncComponent(() => import('@/views/admin/Auth/AdminRegistration/RegSteps/RegStep1.vue')),
    Step2: defineAsyncComponent(() => import('@/views/admin/Auth/AdminRegistration/RegSteps/RegStep2.vue')),
    Step3: defineAsyncComponent(() => import('@/views/admin/Auth/AdminRegistration/RegSteps/RegStep3.vue')),
  },
  setup() {
    const { store, adminRegistration } = useTemporaryStore();

    const currentStep = computed(() => adminRegistration.value.step);

    const onNext = async () => {
      store.updateAdminRegistrationState({
        step: currentStep.value + 1,
      });
    };

    const onError = () => {
      router.push('/admin-link-expired');
      store.updateAdminRegistrationState({ step: 1 });
    };

    const onComplete = () => {
      router.push('/');
    };

    onBeforeUnmount(() => {
      store.updateAdminRegistrationState({ step: 1 });
    });

    return {
      currentStep,

      onNext,
      onError,
      onComplete,
    };
  },
};
</script>
